<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #1284ee">
			<svg width="500" height="150" viewBox="0 0 500 150" preserveAspectRatio="none">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#ccdded"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#1284ee"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#1284ee"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">Encore Music Wiz</p>
				<p class="banner-title-bold">Audio Playback App</p>
            </div>
		</div>

		<div class="home-spacer"></div>

		<div id="content">
			<div class="column-container musicwiz-column">
				<div class="column no-grow">
					<img class="musicwiz" src="@/assets/images/musicwiz.png">
				</div>

				<div class="column">
					<p class="info-bold">Encore Music Wiz is our exclusive audio playback app for smartphones and tablets.</p>
					<p class="info">The app is an outstanding resource for both teachers and students. It utilises the latest time and pitch stretching software, allowing teachers and students to play our high quality recordings at any tempo and in a range of keys.</p>
					<p class="info">Please follow the correct install sequence before using the app.</p>
					<button class="button-round" @click="musicWizInstructions">Get the app</button>
				</div>
			</div>
				
			<div class="smart-coloured-container musicwiz">
				<p class="course-section-heading">Key Features</p>
				<ul>
					<li class="course-info"><span>Backing Track Access</span> – access Encore’s world-class arrangements. These provide outstanding aural models for teachers and students</li>
					<li class="course-info"><span>Transposition Function</span> – encourages students to play their pieces in a range of keys </li>
					<li class="course-info"><span>Tempo Function</span> – allows students to access multiple practice tempi for different stages of progress</li>
					<li class="course-info"><span>Metronome</span> – facilitates tempo and rhythmic accuracy</li>
					<li class="course-info"><span>Encourages Creativity</span> – backing tracks provide a framework for musical creativity such as improvisation and other extensions</li>
				</ul>
			</div>

			<div class="screenshots">
				<img src="@/assets/images/screenshot1.png">
				<img src="@/assets/images/screenshot2.png">
			</div>
			
			<p class="heading-3">All Encore books come with a Redemption Code to download the backing tracks through the app.</p>
			<p class="info">
				<ul>
					<li>Each Redemption Code is valid for single use only</li>
					<li>It cannot be shared with another user or user account</li>
					<li>It is not transferable</li>
				</ul>
			</p>

			<p class="heading-2">How to use Encore Music Wiz</p>
			<p class="info">
				<ol>
					<li>Purchase an Encore Music Education book with a Redemption Code</li>
					<li>Visit <a href="https://www.encoremusicwiz.com.au/instructions" class="info-link">www.encoremusicwiz.com.au/instructions</a></li>
					<li>Follow the correct install sequence before using the app</li>
					<li>For further assistance and FAQs please visit <a href="https://www.encoremusicwiz.com.au/faq" class="info-link">www.encoremusicwiz.com.au/faq</a></li>
				</ol>
			</p>

			<p class="info-bold">Disclaimer</p>
			<p class="info">
				<ul>
					<li>Encore Music Wiz is compatible with mobile devices such as smartphones and tablets. It is not compatible with desktop computers or laptops.</li>
					<li>Redemption Codes are single use only.</li>
					<li>Previous editions of Encore books that came with a CD can be upgraded to the Encore Music Wiz app for a fee. Please contact us for more information.</li>
				</ul>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		musicWizInstructions() {
			window.location.href = 'https://www.encoremusicwiz.com.au/instructions';
		}
	}
}
</script>